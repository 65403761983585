// @import "bootstrap/scss/functions"; 
// @import "bootstrap/scss/variables";
// @import "bootstrap/scss/mixins";

// @import "bootstrap/scss/bootstrap";
.navbar-brand.abs {
    position: absolute;
    width: auto;
    left: 1em;
    text-align: center;
}

@media (min-width: 768px) {
    .navbar-brand.abs {
        position: absolute;
        width: auto;
        left: 50%;
        transform: translateX(-50%);
        text-align: center;
    }
}

.brand {
    position: absolute;
    left: 50%;
    margin-left: -100px !important;
    display:block;
}

.title {
    font-size: 4rem;
    font-weight: 200;
    //display: none;
    text-align: center;
    background-color: rgba(255, 255, 255, 0.5);
}

.trs-logo {
    position: relative;
    margin: auto;
    width: 200px;
    top: 0.5rem;
    left: 10%;
}

.bg-blur {
    text-shadow: 0 0 1px #CCCCCC;
}

.bg-dim {
    background-color: rgba(0,0,0,.15);
    border-radius: 25%;
    box-shadow: 0 0 9px 9px rgba(0,0,0,.15);
}

header {
    height: 85vh;
    /* min-height: 70vh;
    max-height: 80vh; */
}

// .logocontainer {
//     position: relative;
//     display:block;
//     margin-left: auto !important;
//     margin-right: auto !important;
//     left:	calc((100% - 200px) / 2);
//     width:200px;
// }

// .rmd-logo {
//     height: 50px;
// }

.dropbox {
    margin-top: -3em;
    margin-bottom: 1em;
}

// @media (min-width: 576px) { 
//     .dropbox {
//         margin-top: -4em;
//         margin-bottom: 1em;
//     }

//     .title {
//         display:block;
//         background-color: rgba(255,255,255,.5);
//     }

//     .card {
//             width: 100%;
//     }

//     .trs-logo {
//         width:200px;
//         top: .5rem;
//         left: 10%;
//     }

// }


.bg-image-full {
    background: no-repeat scroll;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    background-size: cover;
    -o-background-size: cover;
    background-position: top center;
}

.white {
    color: #FFFFFF !important;
}

.blue {
    color: #24628F !important;
}

.blue-bg {
    background-color: #24628F !important;
}

.green {
    color: #34845C !important;
}

.green-bg {
    background-color: #34845C !important;
}

.card h1 {
    color: white;
}

.card p {
    color: white
}
