/* Template Name: Dorsin
   Author: Themesbrand
   Version: 2.0.0
   File Description: Main Css file of the template
*/
/****************************************\

01.general
02.helper
03.components
04.menu
05.home
06.features
07.sevices
08.cta
09.process
10.pricing
11.team
12.testimonial
13.blog
14.contact
15.footer
16.authentication
17.switcher
18.responsive

*****************************************/
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;700&family=Rubik:wght@300;400;500&display=swap");
body {
  font-family: "Rubik", sans-serif;
  overflow-x: hidden !important;
  position: relative;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Poppins", sans-serif;
}

.btn,
.btn:focus,
button,
button:focus {
  outline: none !important;
}

a {
  text-decoration: none !important;
  outline: none;
}

p {
  font-size: 14.4px;
  line-height: 24px;
}

.row > * {
  position: relative;
}

.section {
  padding-top: 80px;
  padding-bottom: 80px;
  position: relative;
}

.section-lg {
  padding: 100px 0;
}

.vertical-content {
  display: -moz-flex;
  display: flex;
  align-items: center;
  -webkit-align-items: center;
  justify-content: center;
  -webkit-justify-content: center;
  flex-direction: row;
}

.bg-overlay {
  background: linear-gradient(to right, #2B96CC, #1f3886);
  opacity: 0.3;
  position: absolute;
  height: 100%;
  width: 100%;
  right: 0;
  bottom: 0;
  left: 0;
  top: 0;
}

.vh-100 {
  height: 100vh !important;
}

.btn-rounded {
  border-radius: 30px;
}

.box-shadow {
  box-shadow: 0px 3px 6px 1px rgba(38, 107, 193, 0.08);
}

hr {
  border-top: 1px solid #e7ebf3;
}

::selection {
  background-color: #272a33;
  color: #ffffff;
}

.h1,
h1 {
  font-size: 36px;
}

.h2,
h2 {
  font-size: 30px;
}

.h3,
h3 {
  font-size: 24px;
}

.h4,
h4 {
  font-size: 18px;
}

.h5,
h5 {
  font-size: 14px;
}

.h6,
h6 {
  font-size: 12px;
}

b {
  font-weight: 500;
}

.f-10 {
  font-size: 10px;
}

.f-12 {
  font-size: 12px;
}

.f-13 {
  font-size: 13px;
}

.f-14 {
  font-size: 14px;
}

.f-15 {
  font-size: 15px;
}

.f-16 {
  font-size: 16px;
}

.f-18 {
  font-size: 18px;
}

.f-19 {
  font-size: 19px;
}

.f-20 {
  font-size: 20px;
}

.line-height_1_4 {
  line-height: 1.4;
}

.line-height_1_6 {
  line-height: 1.6;
}

.line-height_1_8 {
  line-height: 1.8;
}

.font-weight-500 {
  font-weight: 500;
}

.font-weight-600 {
  font-weight: 600;
}

.section-title {
  text-transform: uppercase;
  letter-spacing: 2px;
  margin-bottom: 0px;
  padding-bottom: 1rem;
  line-height: 1;
  font-size: 1.75rem;
  font-weight: 500;
}

.section-title-border {
  width: 50px;
  height: 2px;
  margin-right: auto;
  margin-left: auto;
  background-color: #4341cc;
}

.section-subtitle {
  letter-spacing: 0.02em;
}

.svg-pattern {
  width: 100%;
  position: relative;
}

iframe {
  max-width: 100%;
}

.hover-effect {
  transition: all 0.3s ease-in-out;
}

.hover-effect:hover {
  transform: translateY(-1rem);
}

.btn {
  padding: 10px 20px;
  font-size: 14px;
  letter-spacing: 0.5px;
  transition: all 0.5s;
}

.btn:focus {
  box-shadow: none;
}

.btn:hover {
  transform: translateY(-5px);
}

.btn-sm {
  padding: 8px 12px;
  font-size: 12px;
}

.shadow {
  box-shadow: 0px 3px 6px 1px rgba(38, 107, 193, 0.08) !important;
}

.bg-primary {
  background-color: #4341cc;
}

.bg-soft-primary {
  background-color: rgba(67, 65, 204, 0.1) !important;
}

.text-primary {
  color: #4341cc !important;
}

.border-primary {
  color: #4341cc !important;
}

.icon-dual-primary {
  color: #4341cc;
  fill: rgba(67, 65, 204, 0.2);
}

.btn-soft-primary {
  background-color: rgba(67, 65, 204, 0.25) !important;
  color: #4341cc !important;
}

.btn-soft-primary:hover,
.btn-soft-primary:focus,
.btn-soft-primary:active,
.btn-soft-primary.active,
.btn-soft-primary.focus,
.btn-soft-primary:not(:disabled):not(.disabled):active,
.btn-soft-primary:not(:disabled):not(.disabled):active:focus,
.btn-soft-primary .open > .dropdown-toggle.btn-primary {
  background: #3735c4;
}

.btn-primary {
  background: #4341cc;
  border-color: #4341cc !important;
}

.btn-primary:hover,
.btn-primary:focus,
.btn-primary:active,
.btn-primary.active,
.btn-primary.focus,
.btn-primary:not(:disabled):not(.disabled):active,
.btn-primary:not(:disabled):not(.disabled):active:focus,
.btn-primary .open > .dropdown-toggle.btn-primary {
  background: #3735c4;
  border-color: #3735c4;
}

.btn-outline-primary {
  color: #4341cc;
  border-color: #4341cc;
}

.btn-outline-primary:hover,
.btn-outline-primary:focus,
.btn-outline-primary:active,
.btn-outline-primary.active,
.btn-outline-primary.focus,
.btn-outline-primary:not(:disabled):not(.disabled):active,
.btn-outline-primary:not(:disabled):not(.disabled):active:focus,
.btn-outline-primary .open > .dropdown-toggle.btn-primary {
  background: #4341cc;
  border-color: #4341cc;
}

.bg-success {
  background-color: #1bbc9d;
}

.bg-soft-success {
  background-color: rgba(27, 188, 157, 0.1) !important;
}

.text-success {
  color: #1bbc9d !important;
}

.border-success {
  color: #1bbc9d !important;
}

.icon-dual-success {
  color: #1bbc9d;
  fill: rgba(27, 188, 157, 0.2);
}

.btn-soft-success {
  background-color: rgba(27, 188, 157, 0.25) !important;
  color: #1bbc9d !important;
}

.btn-soft-success:hover,
.btn-soft-success:focus,
.btn-soft-success:active,
.btn-soft-success.active,
.btn-soft-success.focus,
.btn-soft-success:not(:disabled):not(.disabled):active,
.btn-soft-success:not(:disabled):not(.disabled):active:focus,
.btn-soft-success .open > .dropdown-toggle.btn-primary {
  background: #18aa8e;
}

.btn-success {
  background: #1bbc9d;
  border-color: #1bbc9d !important;
}

.btn-success:hover,
.btn-success:focus,
.btn-success:active,
.btn-success.active,
.btn-success.focus,
.btn-success:not(:disabled):not(.disabled):active,
.btn-success:not(:disabled):not(.disabled):active:focus,
.btn-success .open > .dropdown-toggle.btn-primary {
  background: #18aa8e;
  border-color: #18aa8e;
}

.btn-outline-success {
  color: #1bbc9d;
  border-color: #1bbc9d;
}

.btn-outline-success:hover,
.btn-outline-success:focus,
.btn-outline-success:active,
.btn-outline-success.active,
.btn-outline-success.focus,
.btn-outline-success:not(:disabled):not(.disabled):active,
.btn-outline-success:not(:disabled):not(.disabled):active:focus,
.btn-outline-success .open > .dropdown-toggle.btn-primary {
  background: #1bbc9d;
  border-color: #1bbc9d;
}

.bg-info {
  background-color: #10a6ba;
}

.bg-soft-info {
  background-color: rgba(16, 166, 186, 0.1) !important;
}

.text-info {
  color: #10a6ba !important;
}

.border-info {
  color: #10a6ba !important;
}

.icon-dual-info {
  color: #10a6ba;
  fill: rgba(16, 166, 186, 0.2);
}

.btn-soft-info {
  background-color: rgba(16, 166, 186, 0.25) !important;
  color: #10a6ba !important;
}

.btn-soft-info:hover,
.btn-soft-info:focus,
.btn-soft-info:active,
.btn-soft-info.active,
.btn-soft-info.focus,
.btn-soft-info:not(:disabled):not(.disabled):active,
.btn-soft-info:not(:disabled):not(.disabled):active:focus,
.btn-soft-info .open > .dropdown-toggle.btn-primary {
  background: #0e95a7;
}

.btn-info {
  background: #10a6ba;
  border-color: #10a6ba !important;
}

.btn-info:hover,
.btn-info:focus,
.btn-info:active,
.btn-info.active,
.btn-info.focus,
.btn-info:not(:disabled):not(.disabled):active,
.btn-info:not(:disabled):not(.disabled):active:focus,
.btn-info .open > .dropdown-toggle.btn-primary {
  background: #0e95a7;
  border-color: #0e95a7;
}

.btn-outline-info {
  color: #10a6ba;
  border-color: #10a6ba;
}

.btn-outline-info:hover,
.btn-outline-info:focus,
.btn-outline-info:active,
.btn-outline-info.active,
.btn-outline-info.focus,
.btn-outline-info:not(:disabled):not(.disabled):active,
.btn-outline-info:not(:disabled):not(.disabled):active:focus,
.btn-outline-info .open > .dropdown-toggle.btn-primary {
  background: #10a6ba;
  border-color: #10a6ba;
}

.bg-warning {
  background-color: #f6cb42;
}

.bg-soft-warning {
  background-color: rgba(246, 203, 66, 0.1) !important;
}

.text-warning {
  color: #f6cb42 !important;
}

.border-warning {
  color: #f6cb42 !important;
}

.icon-dual-warning {
  color: #f6cb42;
  fill: rgba(246, 203, 66, 0.2);
}

.btn-soft-warning {
  background-color: rgba(246, 203, 66, 0.25) !important;
  color: #f6cb42 !important;
}

.btn-soft-warning:hover,
.btn-soft-warning:focus,
.btn-soft-warning:active,
.btn-soft-warning.active,
.btn-soft-warning.focus,
.btn-soft-warning:not(:disabled):not(.disabled):active,
.btn-soft-warning:not(:disabled):not(.disabled):active:focus,
.btn-soft-warning .open > .dropdown-toggle.btn-primary {
  background: #f5c62f;
}

.btn-warning {
  background: #f6cb42;
  border-color: #f6cb42 !important;
}

.btn-warning:hover,
.btn-warning:focus,
.btn-warning:active,
.btn-warning.active,
.btn-warning.focus,
.btn-warning:not(:disabled):not(.disabled):active,
.btn-warning:not(:disabled):not(.disabled):active:focus,
.btn-warning .open > .dropdown-toggle.btn-primary {
  background: #f5c62f;
  border-color: #f5c62f;
}

.btn-outline-warning {
  color: #f6cb42;
  border-color: #f6cb42;
}

.btn-outline-warning:hover,
.btn-outline-warning:focus,
.btn-outline-warning:active,
.btn-outline-warning.active,
.btn-outline-warning.focus,
.btn-outline-warning:not(:disabled):not(.disabled):active,
.btn-outline-warning:not(:disabled):not(.disabled):active:focus,
.btn-outline-warning .open > .dropdown-toggle.btn-primary {
  background: #f6cb42;
  border-color: #f6cb42;
}

.bg-danger {
  background-color: #fb3e3e;
}

.bg-soft-danger {
  background-color: rgba(251, 62, 62, 0.1) !important;
}

.text-danger {
  color: #fb3e3e !important;
}

.border-danger {
  color: #fb3e3e !important;
}

.icon-dual-danger {
  color: #fb3e3e;
  fill: rgba(251, 62, 62, 0.2);
}

.btn-soft-danger {
  background-color: rgba(251, 62, 62, 0.25) !important;
  color: #fb3e3e !important;
}

.btn-soft-danger:hover,
.btn-soft-danger:focus,
.btn-soft-danger:active,
.btn-soft-danger.active,
.btn-soft-danger.focus,
.btn-soft-danger:not(:disabled):not(.disabled):active,
.btn-soft-danger:not(:disabled):not(.disabled):active:focus,
.btn-soft-danger .open > .dropdown-toggle.btn-primary {
  background: #fb2a2a;
}

.btn-danger {
  background: #fb3e3e;
  border-color: #fb3e3e !important;
}

.btn-danger:hover,
.btn-danger:focus,
.btn-danger:active,
.btn-danger.active,
.btn-danger.focus,
.btn-danger:not(:disabled):not(.disabled):active,
.btn-danger:not(:disabled):not(.disabled):active:focus,
.btn-danger .open > .dropdown-toggle.btn-primary {
  background: #fb2a2a;
  border-color: #fb2a2a;
}

.btn-outline-danger {
  color: #fb3e3e;
  border-color: #fb3e3e;
}

.btn-outline-danger:hover,
.btn-outline-danger:focus,
.btn-outline-danger:active,
.btn-outline-danger.active,
.btn-outline-danger.focus,
.btn-outline-danger:not(:disabled):not(.disabled):active,
.btn-outline-danger:not(:disabled):not(.disabled):active:focus,
.btn-outline-danger .open > .dropdown-toggle.btn-primary {
  background: #fb3e3e;
  border-color: #fb3e3e;
}

.bg-orange {
  background-color: #f09105;
}

.bg-soft-orange {
  background-color: rgba(240, 145, 5, 0.1) !important;
}

.text-orange {
  color: #f09105 !important;
}

.border-orange {
  color: #f09105 !important;
}

.icon-dual-orange {
  color: #f09105;
  fill: rgba(240, 145, 5, 0.2);
}

.btn-soft-orange {
  background-color: rgba(240, 145, 5, 0.25) !important;
  color: #f09105 !important;
}

.btn-soft-orange:hover,
.btn-soft-orange:focus,
.btn-soft-orange:active,
.btn-soft-orange.active,
.btn-soft-orange.focus,
.btn-soft-orange:not(:disabled):not(.disabled):active,
.btn-soft-orange:not(:disabled):not(.disabled):active:focus,
.btn-soft-orange .open > .dropdown-toggle.btn-primary {
  background: #dc8505;
}

.btn-orange {
  background: #f09105;
  border-color: #f09105 !important;
}

.btn-orange:hover,
.btn-orange:focus,
.btn-orange:active,
.btn-orange.active,
.btn-orange.focus,
.btn-orange:not(:disabled):not(.disabled):active,
.btn-orange:not(:disabled):not(.disabled):active:focus,
.btn-orange .open > .dropdown-toggle.btn-primary {
  background: #dc8505;
  border-color: #dc8505;
}

.btn-outline-orange {
  color: #f09105;
  border-color: #f09105;
}

.btn-outline-orange:hover,
.btn-outline-orange:focus,
.btn-outline-orange:active,
.btn-outline-orange.active,
.btn-outline-orange.focus,
.btn-outline-orange:not(:disabled):not(.disabled):active,
.btn-outline-orange:not(:disabled):not(.disabled):active:focus,
.btn-outline-orange .open > .dropdown-toggle.btn-primary {
  background: #f09105;
  border-color: #f09105;
}

.bg-dark {
  background-color: #272a33;
}

.bg-soft-dark {
  background-color: rgba(39, 42, 51, 0.1) !important;
}

.text-dark {
  color: #272a33 !important;
}

.border-dark {
  color: #272a33 !important;
}

.icon-dual-dark {
  color: #272a33;
  fill: rgba(39, 42, 51, 0.2);
}

.btn-soft-dark {
  background-color: rgba(39, 42, 51, 0.25) !important;
  color: #272a33 !important;
}

.btn-soft-dark:hover,
.btn-soft-dark:focus,
.btn-soft-dark:active,
.btn-soft-dark.active,
.btn-soft-dark.focus,
.btn-soft-dark:not(:disabled):not(.disabled):active,
.btn-soft-dark:not(:disabled):not(.disabled):active:focus,
.btn-soft-dark .open > .dropdown-toggle.btn-primary {
  background: #1e2027;
}

.btn-dark {
  background: #272a33;
  border-color: #272a33 !important;
}

.btn-dark:hover,
.btn-dark:focus,
.btn-dark:active,
.btn-dark.active,
.btn-dark.focus,
.btn-dark:not(:disabled):not(.disabled):active,
.btn-dark:not(:disabled):not(.disabled):active:focus,
.btn-dark .open > .dropdown-toggle.btn-primary {
  background: #1e2027;
  border-color: #1e2027;
}

.btn-outline-dark {
  color: #272a33;
  border-color: #272a33;
}

.btn-outline-dark:hover,
.btn-outline-dark:focus,
.btn-outline-dark:active,
.btn-outline-dark.active,
.btn-outline-dark.focus,
.btn-outline-dark:not(:disabled):not(.disabled):active,
.btn-outline-dark:not(:disabled):not(.disabled):active:focus,
.btn-outline-dark .open > .dropdown-toggle.btn-primary {
  background: #272a33;
  border-color: #272a33;
}

.bg-cyan {
  background-color: #00e6e6;
}

.bg-soft-cyan {
  background-color: rgba(0, 230, 230, 0.1) !important;
}

.text-cyan {
  color: #00e6e6 !important;
}

.border-cyan {
  color: #00e6e6 !important;
}

.icon-dual-cyan {
  color: #00e6e6;
  fill: rgba(0, 230, 230, 0.2);
}

.btn-soft-cyan {
  background-color: rgba(0, 230, 230, 0.25) !important;
  color: #00e6e6 !important;
}

.btn-soft-cyan:hover,
.btn-soft-cyan:focus,
.btn-soft-cyan:active,
.btn-soft-cyan.active,
.btn-soft-cyan.focus,
.btn-soft-cyan:not(:disabled):not(.disabled):active,
.btn-soft-cyan:not(:disabled):not(.disabled):active:focus,
.btn-soft-cyan .open > .dropdown-toggle.btn-primary {
  background: #00d2d2;
}

.btn-cyan {
  background: #00e6e6;
  border-color: #00e6e6 !important;
}

.btn-cyan:hover,
.btn-cyan:focus,
.btn-cyan:active,
.btn-cyan.active,
.btn-cyan.focus,
.btn-cyan:not(:disabled):not(.disabled):active,
.btn-cyan:not(:disabled):not(.disabled):active:focus,
.btn-cyan .open > .dropdown-toggle.btn-primary {
  background: #00d2d2;
  border-color: #00d2d2;
}

.btn-outline-cyan {
  color: #00e6e6;
  border-color: #00e6e6;
}

.btn-outline-cyan:hover,
.btn-outline-cyan:focus,
.btn-outline-cyan:active,
.btn-outline-cyan.active,
.btn-outline-cyan.focus,
.btn-outline-cyan:not(:disabled):not(.disabled):active,
.btn-outline-cyan:not(:disabled):not(.disabled):active:focus,
.btn-outline-cyan .open > .dropdown-toggle.btn-primary {
  background: #00e6e6;
  border-color: #00e6e6;
}

.bg-blue {
  background-color: #2762da;
}

.bg-soft-blue {
  background-color: rgba(39, 98, 218, 0.1) !important;
}

.text-blue {
  color: #2762da !important;
}

.border-blue {
  color: #2762da !important;
}

.icon-dual-blue {
  color: #2762da;
  fill: rgba(39, 98, 218, 0.2);
}

.btn-soft-blue {
  background-color: rgba(39, 98, 218, 0.25) !important;
  color: #2762da !important;
}

.btn-soft-blue:hover,
.btn-soft-blue:focus,
.btn-soft-blue:active,
.btn-soft-blue.active,
.btn-soft-blue.focus,
.btn-soft-blue:not(:disabled):not(.disabled):active,
.btn-soft-blue:not(:disabled):not(.disabled):active:focus,
.btn-soft-blue .open > .dropdown-toggle.btn-primary {
  background: #235aca;
}

.btn-blue {
  background: #2762da;
  border-color: #2762da !important;
}

.btn-blue:hover,
.btn-blue:focus,
.btn-blue:active,
.btn-blue.active,
.btn-blue.focus,
.btn-blue:not(:disabled):not(.disabled):active,
.btn-blue:not(:disabled):not(.disabled):active:focus,
.btn-blue .open > .dropdown-toggle.btn-primary {
  background: #235aca;
  border-color: #235aca;
}

.btn-outline-blue {
  color: #2762da;
  border-color: #2762da;
}

.btn-outline-blue:hover,
.btn-outline-blue:focus,
.btn-outline-blue:active,
.btn-outline-blue.active,
.btn-outline-blue.focus,
.btn-outline-blue:not(:disabled):not(.disabled):active,
.btn-outline-blue:not(:disabled):not(.disabled):active:focus,
.btn-outline-blue .open > .dropdown-toggle.btn-primary {
  background: #2762da;
  border-color: #2762da;
}

.bg-muted {
  background-color: #95a0ab;
}

.bg-soft-muted {
  background-color: rgba(149, 160, 171, 0.1) !important;
}

.text-muted {
  color: #95a0ab !important;
}

.border-muted {
  color: #95a0ab !important;
}

.icon-dual-muted {
  color: #95a0ab;
  fill: rgba(149, 160, 171, 0.2);
}

.btn-soft-muted {
  background-color: rgba(149, 160, 171, 0.25) !important;
  color: #95a0ab !important;
}

.btn-soft-muted:hover,
.btn-soft-muted:focus,
.btn-soft-muted:active,
.btn-soft-muted.active,
.btn-soft-muted.focus,
.btn-soft-muted:not(:disabled):not(.disabled):active,
.btn-soft-muted:not(:disabled):not(.disabled):active:focus,
.btn-soft-muted .open > .dropdown-toggle.btn-primary {
  background: #8a96a2;
}

.btn-muted {
  background: #95a0ab;
  border-color: #95a0ab !important;
}

.btn-muted:hover,
.btn-muted:focus,
.btn-muted:active,
.btn-muted.active,
.btn-muted.focus,
.btn-muted:not(:disabled):not(.disabled):active,
.btn-muted:not(:disabled):not(.disabled):active:focus,
.btn-muted .open > .dropdown-toggle.btn-primary {
  background: #8a96a2;
  border-color: #8a96a2;
}

.btn-outline-muted {
  color: #95a0ab;
  border-color: #95a0ab;
}

.btn-outline-muted:hover,
.btn-outline-muted:focus,
.btn-outline-muted:active,
.btn-outline-muted.active,
.btn-outline-muted.focus,
.btn-outline-muted:not(:disabled):not(.disabled):active,
.btn-outline-muted:not(:disabled):not(.disabled):active:focus,
.btn-outline-muted .open > .dropdown-toggle.btn-primary {
  background: #95a0ab;
  border-color: #95a0ab;
}

.bg-purple {
  background-color: #704fff;
}

.bg-soft-purple {
  background-color: rgba(112, 79, 255, 0.1) !important;
}

.text-purple {
  color: #704fff !important;
}

.border-purple {
  color: #704fff !important;
}

.icon-dual-purple {
  color: #704fff;
  fill: rgba(112, 79, 255, 0.2);
}

.btn-soft-purple {
  background-color: rgba(112, 79, 255, 0.25) !important;
  color: #704fff !important;
}

.btn-soft-purple:hover,
.btn-soft-purple:focus,
.btn-soft-purple:active,
.btn-soft-purple.active,
.btn-soft-purple.focus,
.btn-soft-purple:not(:disabled):not(.disabled):active,
.btn-soft-purple:not(:disabled):not(.disabled):active:focus,
.btn-soft-purple .open > .dropdown-toggle.btn-primary {
  background: #5f3bff;
}

.btn-purple {
  background: #704fff;
  border-color: #704fff !important;
}

.btn-purple:hover,
.btn-purple:focus,
.btn-purple:active,
.btn-purple.active,
.btn-purple.focus,
.btn-purple:not(:disabled):not(.disabled):active,
.btn-purple:not(:disabled):not(.disabled):active:focus,
.btn-purple .open > .dropdown-toggle.btn-primary {
  background: #5f3bff;
  border-color: #5f3bff;
}

.btn-outline-purple {
  color: #704fff;
  border-color: #704fff;
}

.btn-outline-purple:hover,
.btn-outline-purple:focus,
.btn-outline-purple:active,
.btn-outline-purple.active,
.btn-outline-purple.focus,
.btn-outline-purple:not(:disabled):not(.disabled):active,
.btn-outline-purple:not(:disabled):not(.disabled):active:focus,
.btn-outline-purple .open > .dropdown-toggle.btn-primary {
  background: #704fff;
  border-color: #704fff;
}

.bg-pink {
  background-color: #f85f89;
}

.bg-soft-pink {
  background-color: rgba(248, 95, 137, 0.1) !important;
}

.text-pink {
  color: #f85f89 !important;
}

.border-pink {
  color: #f85f89 !important;
}

.icon-dual-pink {
  color: #f85f89;
  fill: rgba(248, 95, 137, 0.2);
}

.btn-soft-pink {
  background-color: rgba(248, 95, 137, 0.25) !important;
  color: #f85f89 !important;
}

.btn-soft-pink:hover,
.btn-soft-pink:focus,
.btn-soft-pink:active,
.btn-soft-pink.active,
.btn-soft-pink.focus,
.btn-soft-pink:not(:disabled):not(.disabled):active,
.btn-soft-pink:not(:disabled):not(.disabled):active:focus,
.btn-soft-pink .open > .dropdown-toggle.btn-primary {
  background: #f74b7b;
}

.btn-pink {
  background: #f85f89;
  border-color: #f85f89 !important;
}

.btn-pink:hover,
.btn-pink:focus,
.btn-pink:active,
.btn-pink.active,
.btn-pink.focus,
.btn-pink:not(:disabled):not(.disabled):active,
.btn-pink:not(:disabled):not(.disabled):active:focus,
.btn-pink .open > .dropdown-toggle.btn-primary {
  background: #f74b7b;
  border-color: #f74b7b;
}

.btn-outline-pink {
  color: #f85f89;
  border-color: #f85f89;
}

.btn-outline-pink:hover,
.btn-outline-pink:focus,
.btn-outline-pink:active,
.btn-outline-pink.active,
.btn-outline-pink.focus,
.btn-outline-pink:not(:disabled):not(.disabled):active,
.btn-outline-pink:not(:disabled):not(.disabled):active:focus,
.btn-outline-pink .open > .dropdown-toggle.btn-primary {
  background: #f85f89;
  border-color: #f85f89;
}

.bg-white {
  background-color: #ffffff;
}

.bg-soft-white {
  background-color: rgba(255, 255, 255, 0.1) !important;
}

.text-white {
  color: #ffffff !important;
}

.border-white {
  color: #ffffff !important;
}

.icon-dual-white {
  color: #ffffff;
  fill: rgba(255, 255, 255, 0.2);
}

.btn-soft-white {
  background-color: rgba(255, 255, 255, 0.25) !important;
  color: #ffffff !important;
}

.btn-soft-white:hover,
.btn-soft-white:focus,
.btn-soft-white:active,
.btn-soft-white.active,
.btn-soft-white.focus,
.btn-soft-white:not(:disabled):not(.disabled):active,
.btn-soft-white:not(:disabled):not(.disabled):active:focus,
.btn-soft-white .open > .dropdown-toggle.btn-primary {
  background: whitesmoke;
}

.btn-white {
  background: #ffffff;
  border-color: #ffffff !important;
}

.btn-white:hover,
.btn-white:focus,
.btn-white:active,
.btn-white.active,
.btn-white.focus,
.btn-white:not(:disabled):not(.disabled):active,
.btn-white:not(:disabled):not(.disabled):active:focus,
.btn-white .open > .dropdown-toggle.btn-primary {
  background: whitesmoke;
  border-color: whitesmoke;
}

.btn-outline-white {
  color: #ffffff;
  border-color: #ffffff;
}

.btn-outline-white:hover,
.btn-outline-white:focus,
.btn-outline-white:active,
.btn-outline-white.active,
.btn-outline-white.focus,
.btn-outline-white:not(:disabled):not(.disabled):active,
.btn-outline-white:not(:disabled):not(.disabled):active:focus,
.btn-outline-white .open > .dropdown-toggle.btn-primary {
  background: #ffffff;
  border-color: #ffffff;
}

.bg-light {
  background-color: #f8fbff;
}

.bg-soft-light {
  background-color: rgba(248, 251, 255, 0.1) !important;
}

.text-light {
  color: #f8fbff !important;
}

.border-light {
  color: #f8fbff !important;
}

.icon-dual-light {
  color: #f8fbff;
  fill: rgba(248, 251, 255, 0.2);
}

.btn-soft-light {
  background-color: rgba(248, 251, 255, 0.25) !important;
  color: #f8fbff !important;
}

.btn-soft-light:hover,
.btn-soft-light:focus,
.btn-soft-light:active,
.btn-soft-light.active,
.btn-soft-light.focus,
.btn-soft-light:not(:disabled):not(.disabled):active,
.btn-soft-light:not(:disabled):not(.disabled):active:focus,
.btn-soft-light .open > .dropdown-toggle.btn-primary {
  background: #e4efff;
}

.btn-light {
  background: #f8fbff;
  border-color: #f8fbff !important;
}

.btn-light:hover,
.btn-light:focus,
.btn-light:active,
.btn-light.active,
.btn-light.focus,
.btn-light:not(:disabled):not(.disabled):active,
.btn-light:not(:disabled):not(.disabled):active:focus,
.btn-light .open > .dropdown-toggle.btn-primary {
  background: #e4efff;
  border-color: #e4efff;
}

.btn-outline-light {
  color: #f8fbff;
  border-color: #f8fbff;
}

.btn-outline-light:hover,
.btn-outline-light:focus,
.btn-outline-light:active,
.btn-outline-light.active,
.btn-outline-light.focus,
.btn-outline-light:not(:disabled):not(.disabled):active,
.btn-outline-light:not(:disabled):not(.disabled):active:focus,
.btn-outline-light .open > .dropdown-toggle.btn-primary {
  background: #f8fbff;
  border-color: #f8fbff;
}

.navbar-custom {
  padding: 20px 0px;
  width: 100%;
  border-radius: 0px;
  z-index: 999;
  margin-bottom: 0px;
  transition: all 0.5s ease-in-out;
}

.navbar-custom .navbar-nav {
  margin-left: 50px;
}

.navbar-custom .navbar-nav li a {
  line-height: 26px;
  color: rgba(255, 255, 255, 0.6);
  font-size: 15px;
  text-transform: capitalize;
  transition: all 0.5s;
  background-color: transparent !important;
  padding: 15px 0;
  margin: 0 10px;
}

.navbar-custom .logo {
  color: #ffffff;
  text-transform: uppercase;
  font-weight: 700;
  letter-spacing: 2px;
  height: auto;
  font-family: "Poppins", sans-serif;
}

.sticky-wrapper {
  position: absolute;
  width: 100%;
}

.navbar-toggler {
  font-size: 24px;
  margin-top: 5px;
  margin-bottom: 0px;
  color: #272a33;
}

.navbar-custom .navbar-nav li.active a,
.navbar-custom .navbar-nav li a:hover,
.navbar-custom .navbar-nav li a:active {
  color: #ffffff;
}

.navbar-toggle {
  font-size: 24px;
  margin-top: 5px;
  margin-bottom: 0px;
  color: #ffffff;
}

.nav .open > a,
.nav .open > a:focus,
.nav .open > a:hover {
  background-color: transparent;
  border-color: #337ab7;
}

.navbar-custom .navbar-toggles {
  padding: 0.25rem 0.75rem;
  font-size: 18px;
  background: 0 0;
  border: 1px solid transparent;
  color: #ffffff;
  outline: 0;
}

.navbar-custom .navbar-toggles-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
}

.menu-toggle {
  padding: 4.5px 10px !important;
}

.menu-toggle span {
  line-height: 27px;
}

.navbar-button {
  margin-left: 20px;
}

/*---Navbar-White--*/
.navbar-custom.navbar-white {
  background-color: #ffffff !important;
  padding: 10px 0;
  box-shadow: 0 10px 33px rgba(0, 0, 0, 0.1);
}

.navbar-custom.navbar-white .navbar-nav li a,
.navbar-custom.navbar-white .navbar-brand,
.navbar-white .navbar-toggle {
  color: #393f4f !important;
}

/*---NAVBAR LIGHT--*/
.navbar-light .navbar-nav li a {
  color: rgba(255, 255, 255, 0.5) !important;
}

.navbar-light .navbar-nav li.active a,
.navbar-light .navbar-nav li:hover,
.navbar-light .navbar-nav li:active {
  color: #ffffff !important;
}

.navbar-light .navbar-toggler {
  border: none;
}

/*---NAVBAR STICKY--*/
.nav-sticky.navbar-custom {
  margin-top: 0px;
  padding: 10px 0px;
  box-shadow: 0px 3px 10px 0px rgba(38, 107, 193, 0.08);
}

.nav-sticky.navbar-custom.sticky-dark {
  background-color: #272a33;
}

.nav-sticky .navbar-nav li a {
  color: rgba(255, 255, 255, 0.6) !important;
}

.nav-sticky.navbar-custom .navbar-nav li.active a,
.nav-sticky.navbar-custom .navbar-nav li a:hover,
.nav-sticky.navbar-custom .navbar-nav li a:active {
  color: #ffffff !important;
}

.nav-sticky.navbar-custom .navbar-toggles {
  padding: 0.25rem 0.75rem;
  border: 1px solid transparent;
  outline: 0;
}

.nav-sticky .navbar-nav {
  margin-top: 0px;
}

.is-sticky .navbar-custom {
  margin-top: 0px;
  padding: 10px 0px;
  background-color: #272a33;
  box-shadow: 0 10px 33px rgba(0, 0, 0, 0.1);
}

.bg-home {
  background: url("../images/bg-home.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.home-desc {
  color: rgba(255, 255, 255, 0.75);
  max-width: 600px;
  margin: 0 auto;
}

@media (max-width: 768px) {
  .home-header {
      overflow : hidden;
      height : calc(100vh * .45);
      background: radial-gradient(at 50% 20%, #00000000 30%, #00000080)
  }  

  .home-desc {
    font-size: 1em;
    line-height: 1.1em;
  }
}

@media (min-width: 768px) {
  .home-header {
      overflow : hidden;
      height : calc(100vh * .86);
  }
  
  .home-title {
    font-size: 3em;
    line-height: 1.2em;
  }

  .home-desc {
    font-size: 2em;
    line-height: 1.2em;
  }
}
.box-text {
  text-align: left !important;
}

.display-table-cell {
  display: table-cell;
  vertical-align: middle;
}

.display-table {
  display: table;
  width: 100%;
  height: 100%;
}

.play-btn {
  border: 2px solid;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  display: block;
  font-size: 30px;
  margin-right: auto;
  margin-left: auto;
  line-height: 56px;
  margin-top: 10px;
  text-align: center;
}

.play-shadow {
  border-radius: 50%;
  border: 1px solid transparent;
  width: 82px;
  height: 82px;
  margin: 20px auto;
}

/* ------------- Demo 1 bg-pattern ------------- */
.home-half {
  padding-bottom: 150px;
  padding-top: 220px;
}

.bg-pattern-effect {
  position: absolute;
  bottom: 0px;
  left: 0px;
  right: 0px;
}

.bg-pattern-effect img {
  max-width: 100%;
}

.home-modal {
  border: 0;
  background-color: transparent;
}

.home-modal .btn-close {
  width: 36px;
  height: 36px;
  background-color: rgba(255, 255, 255, 0.5);
  border-radius: 10px;
  position: relative;
  top: 70px;
  z-index: 3;
}

.home-modal .btn-close:hover,
.home-modal .btn-close:focus {
  opacity: 0.5;
}

.home-modal video:focus {
  outline: none;
}

.video-box {
  width: 100%;
}

/* ------- Demo 2 wave animation --------- */
.wave-effect {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  height: 150px;
  overflow: hidden;
}

.shape-one {
  z-index: 15;
  opacity: 0.5;
}

.shape-two {
  z-index: 10;
  opacity: 0.75;
}

.shape-three {
  z-index: 5;
}

.wave {
  position: absolute;
  left: 0;
  width: 200%;
  height: 100%;
  background-repeat: repeat no-repeat;
  background-position: 0 bottom;
  transform-origin: center bottom;
}

.waves-shape {
  position: absolute;
  bottom: -1px;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.wave-one {
  background-size: 50% 100px;
}

.wave-anim .wave-one {
  animation: move-wave 3s;
  -webkit-animation: move-wave 3s;
  animation-delay: 1s;
}

.wave-two {
  background-size: 50% 120px;
}

.wave-anim .wave-two {
  animation: move_wave 10s linear infinite;
}

.wave-three {
  background-size: 50% 100px;
}

.wave-anim .wave-three {
  animation: move_wave 15s linear infinite;
}

@keyframes move_wave {
  0% {
    transform: translateX(0) translateZ(0) scaleY(1);
  }
  50% {
    transform: translateX(-25%) translateZ(0) scaleY(0.55);
  }
  100% {
    transform: translateX(-50%) translateZ(0) scaleY(1);
  }
}

/*-------Demo 3 Rgistartion form ------*/
.home-registration {
  padding-top: 190px;
}

.home-registration-form .form-heading {
  margin-bottom: 25px;
}

.home-registration-form {
  padding: 15px 32px 32px 32px;
  border-radius: 3px;
  box-shadow: 0px 0px 3px 0px #000000;
}

.home-subtitle-width-100 {
  max-width: 100%;
}

.small-text {
  font-size: 14px;
}

/*----Demo 4 Dashboard image ----*/
.home-cell-bottom {
  display: table-cell;
  vertical-align: bottom;
}

.home-padding-t-150 {
  padding-top: 150px;
}

/*---Demo 6 Subscribe -----*/
.subscribe-form input {
  padding: 15px 20px;
  width: 100%;
  font-size: 17px;
  color: #4c5667 !important;
  border: none;
  outline: none !important;
  padding-right: 180px;
  padding-left: 30px;
  background-color: rgba(255, 255, 255, 0.85);
  border-radius: 30px;
}

.subscribe-form button {
  position: absolute;
  text-transform: uppercase;
  top: 6px;
  right: 8px;
  outline: none !important;
  border-radius: 30px;
  font-size: 16px;
  padding: 9px 30px;
}

.subscribe-form form {
  position: relative;
  max-width: 600px;
  margin: 0px auto;
}

/*----Demo 8 Home with video --------*/
.frame-border {
  border: 9px solid rgba(0, 0, 0, 0.3);
  border-radius: 5px 5px 5px 5px;
}

/*---Demo 9 Animated ---*/
.slidero {
  top: 50%;
  transform: translateY(-50%);
  position: absolute;
  z-index: 100;
  left: 0;
  right: 0;
}

.features-box h3 {
  margin-bottom: 25px;
  margin-top: 0;
  line-height: 36px;
}

.features-box .features-item-list li {
  position: relative;
  line-height: 24px;
  font-size: 15px;
  padding: 7px 0 7px 20px;
}

.features-box .features-item-list li:before {
  content: "";
  border-radius: 50%;
  width: 7px;
  height: 7px;
  position: absolute;
  left: 0;
  top: 14px;
}

.features-img {
  max-width: 500px;
  margin: 0 0 0 auto;
}

.services-box {
  padding: 15px;
}

.services-box i {
  font-size: 32px;
  height: 68px;
  width: 68px;
  background-color: #ffffff;
  border-radius: 50%;
  box-shadow: 0 0 1.25rem rgba(108, 118, 134, 0.1) !important;
  line-height: 68px;
  margin-bottom: 15px;
}

.services-box:hover i {
  color: #ffffff !important;
  transition: all 0.3s ease-in-out;
}

.bg-web-desc {
  background: url("../images/img-1.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.bg-get-start {
  background: url("../images/trsheader.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.process-box i {
  font-size: 48px;
  line-height: 72px;
}

.process-box .process-left-icon {
  content: "";
  position: absolute;
  top: 10px;
  background-color: #272a33;
  border-radius: 50%;
  height: 50px;
  width: 50px;
}

.plan-line:before {
  content: "";
  border: 1px dashed #e0e0e0;
  position: absolute;
  width: 67%;
  left: 65%;
  top: 50px;
}

.process-left-icon-1 i,
.process-left-icon-2 i {
  font-size: 35px;
  border-radius: 50%;
  position: absolute;
  top: 83px;
  color: #ffffff;
  z-index: 9;
}

.process-left-icon-1 i {
  left: 63%;
}

.process-left-icon-2 i {
  left: 30%;
}

.pricing-box {
  padding: 50px 40px 50px 40px;
  border: 1px solid #ecf0f5;
  border-radius: 8px;
  box-shadow: 0 0 1.25rem rgba(108, 118, 134, 0.1) !important;
}

.pricing-box h4 {
  font-size: 15px;
}

.pricing-box h1 {
  font-size: 36px;
  padding-top: 5px;
}

.pricing-box .pricing-border {
  width: 100%;
  height: 1px;
  margin-top: 35px;
  background-color: #ecf0f5;
}

.pricing-box .plan-features p {
  padding-top: 5px;
}

.price-active {
  box-shadow: 0 0rem 4rem rgba(0, 0, 0, 0.1) !important;
}

.team-box {
  border-bottom: 1px solid #ecf0f5;
}

.team-box .team-name {
  font-size: 16px;
  padding-top: 20px;
  margin-bottom: 7px;
}

.team-box .team-designation {
  font-size: 13px;
  color: #a8aeb4;
}

.testimonial-box img {
  margin: -40px auto;
  height: 68px;
  width: 68px;
}

.testimonial-box .testimonial-decs {
  border: 1px solid #ecf0f5;
  border-radius: 8px;
  transition: all 0.3s ease-in-out;
  box-shadow: 0 0 1.25rem rgba(108, 118, 134, 0.1) !important;
}

.testimonial-box .testimonial-decs p {
  padding: 60px 25px 30px;
  line-height: 24px;
  font-style: italic;
}

.blog-box .blog-title {
  color: #272a33;
  transition: all 0.5s;
}

.blog-box .blog-title:hover {
  color: #4341cc;
}

.blog-box .read-btn {
  font-size: 15px;
  color: #4341cc;
}

.blog-box .read-btn:hover {
  color: #272a33 !important;
}

textarea.form-control {
  height: auto !important;
}

.form-control {
  margin-bottom: 15px;
  box-shadow: none !important;
  height: 50px;
  padding-left: 20px;
  border: 1px solid #e0e0e0;
  font-size: 14px;
}

.form-control:focus {
  border-color: #ccc;
}

textarea.form-control {
  height: auto;
  padding-top: 13.5px;
}

.contact-social {
  padding: 20px 0px;
}

.contact-social .social-icon {
  border: 2px solid #afb5bd;
  border-radius: 50%;
  color: #afb5bd;
  width: 48px;
  height: 48px;
  display: block;
  font-size: 19px;
  line-height: 44px;
  text-align: center;
}

.contact-social .contact-title {
  font-size: 20px;
}

.error_message {
  padding: 10px;
  background-color: #fdf0d3;
  margin-bottom: 20px;
  text-align: center;
  font-size: 14px;
}

#success_page {
  background-color: rgba(27, 188, 157, 0.25);
  padding: 10px 20px;
  border-radius: 4px;
}

.footer {
  padding: 50px 0;
  background-color: #272a33;
}

.footer h4 {
  color: #ffffff;
}

.footer .footer-list li a {
  color: #98a0aa;
  padding-top: 8px;
  display: inline-block;
  font-size: 14px;
  transition: all 0.3s ease-in-out;
}

.footer .footer-list li ahover {
  color: #dce6f3;
}

.subscribe {
  position: relative;
  width: 100%;
  margin-top: 15px;
  margin-bottom: 25px;
}

.subscribe input {
  height: 40px;
  background-color: #2e313a !important;
  box-shadow: none !important;
  border: none;
  padding-right: 50px;
  color: #ffffff !important;
}

.subscribe .submit {
  position: absolute;
  top: 0;
  right: 0;
  font-size: 20px;
  width: 50px;
  line-height: 45px;
  text-align: center;
  color: #acacac !important;
}

.footer-alt {
  padding: 15px 0;
  background-color: #2e313a;
}

.footer-alt .copy-rights {
  margin-bottom: 0;
  line-height: 36px;
}

.bg-account-pages {
  background: linear-gradient(to right, #2B96CC, #1f3886);
  opacity: 0.9;
  height: 100%;
}

.account-home-btn {
  position: absolute;
  top: 18px;
  right: 28px;
  z-index: 1;
}

.account-pages-logo {
  letter-spacing: 2px;
}

.account-card form .form-control {
  height: 45px;
}

.account-card form label {
  font-size: 15px;
  margin-bottom: 10px;
}

.account-card .alert {
  font-size: 14px;
}

#style-switcher div h3 {
  font-size: 16px;
  margin: 8px 3px 12px;
}

#style-switcher {
  background: none repeat scroll 0 0 #ffff;
  box-shadow: 2px 2px 0 0 rgba(0, 0, 0, 0.08);
  left: -189px;
  position: fixed;
  top: 17%;
  width: 189px;
  z-index: 9999;
  padding: 10px 5px;
}

#style-switcher div {
  padding: 5px 10px;
}

#style-switcher .bottom {
  background: none repeat scroll 0 0 #ffffff;
  color: #272a33;
  padding: 0;
}

#style-switcher .bottom a.settings {
  background: none repeat scroll 0 0 #2b2424;
  box-shadow: 2px 2px 0 0 rgba(0, 0, 0, 0.08);
  display: block;
  height: 41px;
  position: absolute;
  right: -40px;
  top: 0;
  width: 40px;
  padding: 3px;
}

#style-switcher .bottom a {
  text-decoration: none;
}

#style-switcher .bottom a.settings i {
  margin-left: 5px;
  color: #ffffff;
  font-size: 24px;
  position: absolute;
}

ul.pattern {
  list-style: none outside none;
  margin: 0 0 0px;
  overflow: hidden;
  padding: 0;
  border-radius: 0px;
}

ul.pattern li {
  float: left;
  margin: 2px;
}

ul.pattern li a {
  cursor: pointer;
  display: block;
  height: 35px;
  width: 35px;
  border-radius: 50%;
  margin: 5px;
}

ul.pattern .color1 {
  background-color: #fb3e3e;
}

ul.pattern .color2 {
  background-color: #1bbc9d;
}

ul.pattern .color3 {
  background-color: #f85f89;
}

ul.pattern .color4 {
  background-color: #00e6e6;
}

ul.pattern .color5 {
  background-color: #704fff;
}

ul.pattern .color6 {
  background-color: #2762da;
}

ul.pattern .color7 {
  background-color: #f09105;
}

ul.pattern .color8 {
  background-color: #f6cb42;
}

@media only screen and (max-width: 479px) {
  #style-switcher {
    display: none;
  }
}

@media (min-width: 200px) and (max-width: 1024px) {
  .navbar-custom {
    margin-top: 0px;
    padding: 10px 0px !important;
    box-shadow: 0 10px 33px rgba(0, 0, 0, 0.1);
    color: #444444 !important;
    background: #fdfdfd;
  }
  .navbar-custom .navbar-nav {
    margin-left: 0px;
  }
  .navbar-custom .navbar-nav li.active {
    border-color: transparent;
  }
  .navbar-custom .navbar-nav li a {
    transition: all 0.4s;
    margin: 0px;
    color: rgba(255, 255, 255, 0.6) !important;
  }
  .navbar-custom > .container {
    width: 90%;
  }
  .navbar-white .navbar-toggler {
    color: #272a33 !important;
  }
  .navbar-button {
    margin-top: 15px;
    margin-left: 0px;
  }
  .navbar-custom .navbar-nav li.active a,
  .navbar-custom .navbar-nav li a:hover,
  .navbar-custom .navbar-nav li a:active {
    color: #ffffff !important;
  }
  .navbar-toggler {
    font-size: 24px;
    margin-top: 0px;
    margin-bottom: 0px;
    color: #ffffff;
  }
  .navbar-toggler:focus {
    box-shadow: none;
  }
  .home-title {
    font-size: 1.3em;
  }
  .bg-pattern-effect {
    bottom: -8px;
  }
  .home-half {
    padding-bottom: 100px;
    padding-top: 130px;
  }
  .features-img {
    margin-top: 30px;
  }
  .pricing-box {
    margin-top: 30px;
  }
  .team-box {
    margin-top: 45px;
  }
  .float-left.pull-none {
    float: none !important;
    text-align: center;
  }
  .float-right.pull-none {
    float: none !important;
    text-align: center;
  }
}

@media (max-width: 991px) and (min-width: 200px) {
  .plan-line:before {
    content: "";
    display: none;
  }
  .process-left-icon-1 i,
  .process-left-icon-2 i {
    display: none;
  }
  .contact-title {
    text-align: left;
  }
  .testimonial-box {
    margin-top: 80px !important;
  }
}

@media (min-width: 1200px) {
  .container {
    max-width: 1140px !important;
  }
}
