h3 {
  font-style: italic;

}

h5 {
  font-style: italic;
}


video {
  display: block;
}

/* modified style adding */

.canvasBox {  
  /* height: 90%; */
  width:100%;
  max-width: 1024px;
  text-align: center;
  position: relative;
  margin:auto;
  left: 0;
  top: 0;
  /* transform: translate(-50%, -50%); */
  /* padding: 20px; */
  z-index: -1;
}

.canvasBox input {  
  background-color: #FFF;
  font-size: 18px;
  border: none;
  /* box-shadow: 0 2px 8px 0 rgba(88, 88, 88, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.068); */
  border-radius: 5px;
}

.videoFrame {
  position: relative;
  width: 100%;
  max-height: 90vh;
  z-index: -2;
  margin: 0;
}

.canvasFrame {
  position: absolute;
  left: 0;
  top: 0;
  /* width:100%; */
  /* height:100%; */
  margin:0;
  z-index: -1;
  /* transform: translate(-50%, -50%); */
}

.vci {
  position: absolute;
  left: 50%;
  top: 60%;
  transform: translate(-50%, -50%);
}

.blackContainer {
  display:block;
  text-align: center !important;
  background-color: black;
  height: 100%;
  width: 100%;
}

/* .btn-capture:disabled {
  color: white;
  background-color: rgb(53, 53, 53);
  width: 50%;
  padding: 10px 30px;
  max-width: 250px;
  border: none;
  margin: 0 auto;
  margin: 20px 0;  
} */


.btn-capture {
  color: black;
  background-color: #ffffff;
  line-height: 32px;
  width: 64px;
  margin: .5rem;
  padding: 12px;
  border: none;
  cursor: pointer;
  border-radius: 100%;
}

.btn-capture:hover {  
  box-shadow: -1px 3px 5px 3px #888888;  
} 

.splash {
  display:flex;
  position:absolute;
  justify-content:center;
  align-content: center;
  flex-direction: column;
  text-align: center;
  background-color: black;
  color:white !important;
  width: 100%;
  height:100vh;
  font-size: xx-large;
}

.shutterButton {
  position: fixed;
  flex-wrap: nowrap;
  display: block;
  text-align: center;
  padding-top: 0;
  padding-bottom: 0;
  width:100%;
  max-width: 1024px;
  background-color: transparent;
}


.inputElements {
  min-width: 3rem;
  max-width: 6rem;
  padding:.4em;
  text-align: center;
  margin:.5rem;
}

/* loading css */

.isLoading {
  width: 90vw;
  height: 100vh;
  background-color: rgb(255, 255, 255);
  margin: 0 auto;
  z-index: 1000;
  padding: 0;
}

.isLoading h1 {
  text-align: center;
  align-items: center;
}